.text-center {
    text-align: center;

    .dashboard-text-title {
        font-size: 16px;
    }

    .dashboard-text-content {
        font-size: 24px;
        font-weight: bolder;
        line-height: 28px;
    }
}

.chartbox {
    padding: 10px 20px 30px !important;
}

.one-chart {
    max-width: 100%;
    border: 1px solid lightgray;
    padding: 16px;
    margin: 16px 8px 32px;

    >div:last-child {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-top: 4px;
    }
}

.two-one-chart {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    >div {
        border: 1px solid lightgray;
        padding: 16px;
        margin: 16px 8px;

        >div:last-child {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            padding-top: 4px;
        }
    }

    >div:first-child {
        flex: 2;
    }

    >div:last-child {
        flex: 1;
    }
}

.one-one-chart {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    >div {
        flex: 1;
    }

    .half-chart {
        border: 1px solid lightgray;
        flex: 1;
        padding: 16px;
        margin: 0 8px 32px;

        >div {
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            >div {
                flex: 1;
                margin: 8px;
            }
        }

        .chart-title {
            font-size: 16px;
            white-space: nowrap;
        }

        .chart-value {
            font-size: 20px;
            font-weight: bold;
        }

        >div:last-child {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            padding-top: 4px;
        }
    }

    .half-chart:last-child{
        visibility: hidden;   
    }
}
@primary-color: #046CC1;@link-color: #1890ff;