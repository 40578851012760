.side-nav-logo {
    display: none;
    align-items: center;
    padding: 12px;
    img {
        height: 70px;
        width: auto;
    }
    span{
        padding-left: 8px;
        display: inline-block;
        color: white;
    }
}

.small-logo{
    width: 56px !important;
    object-fit: cover;
    object-position: left;
}

.slider-container {
	overflow-x: auto;
	height: 100%;

	.ant-layout-sider-children {
		display: flex;
		flex-direction: column;
	}

    .version-text {
        color: white;
        font-size: 8px;
        font-weight: normal;
        padding: 24px 0 0 24px;
    }
}
@primary-color: #046CC1;@link-color: #1890ff;