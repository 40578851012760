.site-layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff !important;
    >div {
        display: flex;
        padding: 0 24px;
        
        .profile-menu {
            float: left;
            padding: 0 6px;

            .profile-menu-title {
                padding: 0 0 0 3px;
            }

        }
    }
   
}

@media only screen and (max-width: 600px) {
    .site-layout-header {

        >div .profile-menu .profile-menu-title {
            display: none;
            
        }
    }
}
@primary-color: #046CC1;@link-color: #1890ff;